import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "light",
    info: {
      main: "#6B6B6B",
      light: "#e5e5e5",
      dark: "#b2b2b2",
    },
    primary: {
      main: "rgba(0, 0, 0, 0.87)",
    },
    secondary: {
      main: "#FF9600",
      light: "#FFF7EB",
      dark: "#FFE0AD",
    },
    warning: {
      main: "#14008D",
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingBottom: "16px !important",
        },
      },
    },
  },
});

export default theme;
